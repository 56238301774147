<template>
  <div class="pageContol listWrap templateList addClassCtr">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">班级管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">新增培训</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 style="padding-bottom: 20px">班级信息</h3>
            <el-form
                ref="Form"
                :model="Form"
                :rules="rules"
                label-width="130px"
            >
              <el-form-item
                  label="班级名称："
                  prop="projectName"
                  class="form-item"
              >
                <el-input
                    v-model="Form.projectName"
                    placeholder="请输入班级名称"
                    :disabled="disabled"
                    maxlength="50"
                    show-word-limit
                    size="small"
                />
              </el-form-item>
              <el-form-item label="行政区划：" prop="areaId" class="form-item">
                <el-cascader
                    v-model="Form.areaId"
                    clearable
                    filterable
                    :options="areatreeList"
                    :props="propsarea"
                    :disabled="disabled"
                    @change="areaChange"
                    size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                  label="有效日期："
                  prop="Effectivedate"
                  class="form-item"
              >
                <el-date-picker
                    size="small"
                    v-model="Form.Effectivedate"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                  label="培训人数"
                  prop="projectPeople"
                  class="form-item"
                  required
              >
                <el-input-number
                    v-model="Form.projectPeople"
                    @change="handleChange"
                    :min="1"
                    style="width: 100%"
                    :disabled="disabled"
                    size="small"
                ></el-input-number>
              </el-form-item>

            </el-form>



            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">取消</el-button>
              <el-button @click="seveData()" class="bgc-bv">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
// import tree from "@/components/treePopup";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "addClassManage",
  components: {
    // tree,
  },
  mixins: [UploadPic, DatePicker, List],
  data() {

    return {
      disabled: false,
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      Form: {
        projectId: "",
        projectName: "",
        areaId: "",
        Effectivedate: [],
        projectUser: "",
        projectUserPhone: "",
        projectPeople: "",
      },
      // TrainingMethodsList: [],
      uploadFileList: [],

      params: {},
      rules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        projectPeople: [
          { required: true, message: "请输入培训人数", trigger: "blur" },
        ],
      },
      //上传文件
      // uploadFileDialogVisible: false,
      // tableDataFile: [],
      // ProjectFileStyleList: [],
      // Files: {},
      // disableStu: false,
      // trainTypeIds: [],
      // SubsidyList:[
      //   {
      //     value:true,
      //     label:'是'
      //   },
      //   {
      //     value:false,
      //     label:'否'
      //   }
      // ]
    };
  },
  created() {
    this.getareatree();

    this.getCompanyInfo();
  },
  methods: {
    // 获取鉴定机构信息
    getCompanyInfo(){
      this.$post("/biz/evaluate/company/info").then((ret) => {
        // console.log(ret)
        this.Form.projectUser = ret.data.compUser
        this.Form.projectUserPhone = ret.data.compPhone
      });
    },

    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      // const el1 = this.$refs.Form1;
      el.validate((valid) => {
        if (valid) {
          if (this.Form.enableSurvey && this.params.trainFormId == "11") {
            el1.validate((valid1) => {
              if (valid1) {
                this.doAjax();
                // if (!this.Form.fileKey) {
                //   this.$confirm("开班申请表未上传，是否上传？", "提示", {
                //     confirmButtonText: "是",
                //     cancelButtonText: "否",
                //     type: "warning",
                //   })
                //     .then(() => {})
                //     .catch(() => {
                //       this.doAjax();
                //     });
                // }
              }
            });
          } else if (
              !this.Form.enableSurvey &&
              this.params.trainFormId == "11"
          ) {
            this.doAjax();
            // if (!this.Form.fileKey) {
            //   this.$confirm("开班申请表未上传，是否上传？", "提示", {
            //     confirmButtonText: "是",
            //     cancelButtonText: "否",
            //     type: "warning",
            //   })
            //     .then(() => {})
            //     .catch(() => {
            //       this.doAjax();
            //     });
            // }
          } else if (
              this.Form.areaId.indexOf("130") != -1 &&
              this.params.trainFormId == "5"
          ) {
            if (this.Form.uploadfileList.length < 1) {
              this.$confirm("开班申请表未上传，是否上传？", "提示", {
                confirmButtonText: "是",
                cancelButtonText: "否",
                type: "warning",
              })
                  .then(() => {})
                  .catch(() => {
                    this.doAjax();
                  });
            } else {
              this.doAjax();
            }
          } else {
            this.doAjax();
          }
        }
      });
    },
    // 添加
    doAjax() {
      let parmar = {
        projectName: this.Form.projectName,
        areaId: this.Form.areaId,
        startDate: this.Form.Effectivedate[0],
        endDate: this.Form.Effectivedate[1],
        signEndDate: this.Form.Effectivedate[1],
        projectUser: this.Form.projectUser,
        projectUserPhone: this.Form.projectUserPhone,
        projectPeople: this.Form.projectPeople,
        trainTypeId: '52',
      };
      this.$post("/biz/project/saveOrUpdateTrain", parmar)
          .then((ret) => {
            const name = "classListManage";
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.$router.push({
                name: name,
                params: {
                  refresh: true,
                  isAdd: true,
                  addId: ret.data, //新班级Id，日报做处理
                },
              });
            }
          })
          .catch((err) => {
            return;
          });
    },
    cancle() {
      this.$router.back();
    },
    areaChange(val) {
      if (val.indexOf("41") == -1) {
        this.Form.trainMethod = "10";
      } else {
        this.Form.trainMethod = "";
      }

    },
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
</style>
<style lang="less">
.addClassCtr {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 45%;
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upload-demo {
    .el-upload {
      border: none !important;
      max-width: 5rem !important;
      height: 30px !important;
    }
  }
}
</style>
